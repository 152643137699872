import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import FranchiseContain from '../../../Component/Admin/Franchises';

const Franchise = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Admin" title="Franchise" />
      <FranchiseContain />
    </Fragment>
  );
};
export default Franchise;