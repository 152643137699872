import React, { Fragment, useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import GymList from "./GymList";
import CreateGymWidgets from "./CreateGymWidgets";
import DetailGym from "./DetailGym";
import { getAllGymServiceApi } from "../../../Services/Api.Service";
import { handleDisplayPages, handleIndexData } from "../../../_helper/Pages";
import { toast } from "react-toastify";

const GymContain = () => {
  const [gym, setGym] = useState(null);
  const [gymsData, setGymsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState({
    total: 0,
    active: 1,
    pageSize: 10,
    pagesToShow: 5,
    startPage: 0,
    endPage: 0,
    startIndex: 0,
    endIndex: 0,
  });

  const [pageSize, setPageSize] = useState(10);
  const handleDetailGym = (gym) => {
    setGym(gym);
  };

  useEffect(() => {
    getAllGym();
  }, [pageSize]);

  useEffect(() => {
    getAllGym();
  }, []);

  const handlePageChange = (page) => {
    const [startIndex, endIndex] = handleIndexData(page, pageSize);

    setPages({
      ...pages,
      startIndex: startIndex,
      endIndex: endIndex,
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    const [startIndex, endIndex] = handleIndexData(page, pageSize);

    setPages({
      ...pages,
      startIndex: startIndex,
      endIndex: endIndex,
      pageSize: newPerPage,
    });
    setPageSize(newPerPage);
  };

  const getAllGym = async () => {
    try {
      setLoading(true);
      await getAllGymServiceApi().then(async (resp) => {
        switch (resp.data["statusCode"]) {
          case 200:
            setGymsData(
              resp.data["data"].map((gym) => ({
                id: gym.id_gym,
                name: gym.nama,
                address: gym.alamat,
                latitude: gym.latitude,
                longitude: gym.longitude,
                googleMapLink: gym.link_google,
                img: gym.photo_url,
              }))
            );
            const totalPages = Math.ceil(resp.data["data"].length / pageSize);
            const [startPage, endPage] = handleDisplayPages(
              1,
              pages.pagesToShow,
              totalPages
            );
            const [startIndex, endIndex] = handleIndexData(
              pages.active,
              pageSize
            );
            setPages({
              ...pages,
              total: totalPages,
              startPage: startPage,
              endPage: endPage,
              startIndex: startIndex,
              endIndex: endIndex,
            });
            setLoading(false);
            break;
          default:
            throw new Error(resp);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error("Gagal mendapatkan data, silahkan refresh.");
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="default-dash">
        <Row>
          <GymList
            handleDetailGym={handleDetailGym}
            gymsData={gymsData}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            pages={pages}
            loading={loading}
          />
          <DetailGym gym={gym} />
          <CreateGymWidgets getAllGym={getAllGym} />
        </Row>
      </Container>
    </Fragment>
  );
};
export default GymContain;
