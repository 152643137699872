import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { EmailAddress, Password, SignIn } from "../../../Constant";
import { Bounce, ToastContainer, toast } from "react-toastify";
import FormHeader from "./FormHeader";
import FormPassword from "./FormPassword";
import SignInWith from "./SignInWith";
import {
  getLoggedInUserServiceApi,
  loginServiceApi,
} from "../../../Services/Api.Service";

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const [isRememberMe, setRememberMe] = useState(false);

  const handleChangeRememberMe = () => {
    setRememberMe(!isRememberMe);
  };

  useEffect(() => {
    const email = localStorage.getItem("email");
    setEmail(email ?? "");
  }, []);

  const loginWithJwt = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await loginServiceApi(email, password).then(async (resp) => {
        const data = resp.data.data;
        if (resp.status === 200) {
          if (data.role === "admin") {
            localStorage.setItem(
              "accessToken",
             data.accessToken
            );
            if (isRememberMe) {
              localStorage.setItem("email", data.email);
              localStorage.setItem(
                "refreshToken",
               data.refreshToken
              );
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("refreshToken");
            }

            await getLoggedInUserServiceApi().then((result) => {
              if (result.status !== 200) {
                throw new Error(
                  `Error ${result.status}, Get Profile Failed`
                );
              } else {
                const fullName = result.data["data"]["fullname"].split(" ")
                localStorage.setItem(
                  "lastName",
                  fullName[fullName.length-1]
                );
                localStorage.setItem("email", result.data["data"]["email"]);
                localStorage.setItem("photoUrl", result.data["data"]["foto"]);
                window.location.href = `${process.env.PUBLIC_URL}/admin/gyms`;
              }
            });
          }
        }
      });
    } catch (error) {
      setLoading(false);

      if (error.status === 422) {
        const errors = error.data.errors;
        errors.forEach((err, _) => {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        });
      } else {
        toast.error(error.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Form className="theme-form login-form">
        <FormHeader selected={selected} />
        <FormGroup>
          <Label>{EmailAddress}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-email"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type="email"
              required=""
              placeholder="Contoh : user@email.com"
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-email"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              defaultValue={password}
              required=""
              placeholder="Minimal 8 karakter"
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
        </FormGroup>
        <FormPassword
          isRememberMe={isRememberMe}
          handleChangeRememberMe={handleChangeRememberMe}
        />
        <FormGroup>
          <Btn
            attrBtn={{
              color: "primary",
              className: "btn-block",
              disabled: loading ? loading : loading,
              onClick: (e) => loginWithJwt(e),
            }}
          >
            {loading ? "LOADING..." : SignIn}
          </Btn>
        </FormGroup>
        <SignInWith />
      </Form>
    </Fragment>
  );
};

export default LoginTab;
