import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, InputGroup, Label, Media } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { CreateGymText, CreateFranchiseText, NameFranchiseText, PhoneFranchiseText, EmailFranchiseText, PasswordFranchiseText, AddressFranchiseText } from '../../../Constant';
import axios from 'axios';
import { CreateFranchiseApi } from '../../../api';
import { authHeader } from '../../../Services/Fack.Backend';
import { Bounce, toast } from 'react-toastify';


const AddFranchiseWidgets = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    franchiseName: '',
    franchiseLongitude: 0,
    franchiseLatitude: 0,
    franchisePhone: '',
    franchiseImage: '',
    franchiseEmail: '',
    franchisePassword: '',
    franchiseAddress: '',
  });

  const [isValid, setIsValid] = useState({
    franchiseName: false,
    franchiseLongitude: false,
    franchiseLatitude: false,
    franchisePhone: false,
    franchiseImage: false,
    franchiseEmail: false,
    franchisePassword: false,
    franchiseAddress: false,
  });

  const [isTouched, setIsTouched] = useState({
    franchiseName: false,
    franchiseLongitude: false,
    franchiseLatitude: false,
    franchisePhone: false,
    franchiseImage: false,
    franchiseEmail: false,
    franchisePassword: false,
    franchiseAddress: false,
  });

  const handleChangeSingleInput = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });

    setIsValid({
      ...isValid,
      [name]: value.length > 0 
    });

    setIsTouched({
      ...isTouched,
      [name]: true
    });
  };

  const createFranchise = async (e) => {
    if (Object.values(isValid).every(value => value)) {
      setLoading(true);
      try {
        await axios
          .post(
            CreateFranchiseApi,
            {
              namaFranchise: values.franchiseName,
              longitudeFranchise: parseFloat(values.franchiseLongitude),
              latitudeFranchise: parseFloat(values.franchiseLatitude),
              noTeleponFranchise: values.franchisePhone,
              fotoFranchise: values.franchiseImage,
              emailFranchise: values.franchiseEmail,
              passwordFranchise: values.franchisePassword,
              lokasiFranchise: values.franchiseAddress,
            },
            { headers: authHeader(), }
          ).then((resp) => {
            switch (resp.data['statusCode']) {
              case 200:
                toast.success('Franchise added successfully', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                });

                setValues({
                  franchiseName: '',
                  franchiseLongitude: 0,
                  franchiseLatitude: 0,
                  franchisePhone: '',
                  franchiseImage: '',
                  franchiseEmail: '',
                  franchisePassword: '',
                  franchiseAddress: '',
                });

                setIsValid(
                  Object.keys(isValid).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                  }, {})
                );

                setIsTouched(
                  Object.keys(isTouched).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                  }, {})
                );
                break;
              default:
                throw new Error(resp);
            }
          })
      } catch (error) {
        toast.error('Error', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setLoading(false);
    } else {
      toast.error('Please fill all', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <Fragment>
      <Col xl='6' lg='12' className='dash-xl-100 box-col-12'>
        <Card>
          <CardHeader>
            <Media>
              <Media body>
                <H5>{CreateFranchiseText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="contact-form">
            <Form className="theme-form">
              <div className="form-icon"><i className="icofont icofont-envelope-open"></i></div>
              <FormGroup>
                <Label for="exampleInputName">{NameFranchiseText}</Label>
                <Input
                  name='franchiseName'
                  className="form-control"
                  id="exampleInputName"
                  type="text"
                  onChange={handleChangeSingleInput}
                  value={values.franchiseName}
                  required=""
                  placeholder="Nasi Goreng"
                  valid={isValid.franchiseName && isTouched.franchiseName}
                  invalid={!isValid.franchiseName && isTouched.franchiseName} />
                <FormFeedback valid>Name looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.franchiseName && isTouched.franchiseName).toString()}>Please enter a valid name.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleInputName">{PhoneFranchiseText}</Label>
                <Input
                  name='franchisePhone'
                  className="form-control"
                  id="exampleInputName"
                  type="text"
                  onChange={handleChangeSingleInput}
                  value={values.franchisePhone}
                  required=""
                  placeholder="Nasi Goreng"
                  valid={isValid.franchisePhone && isTouched.franchisePhone}
                  invalid={!isValid.franchisePhone && isTouched.franchisePhone} />
                <FormFeedback valid>Phone number looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.franchisePhone && isTouched.franchisePhone).toString()}>Please enter a valid phone number.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleInputName">{EmailFranchiseText}</Label>
                <Input
                  name='franchiseEmail'
                  className="form-control"
                  id="exampleInputName"
                  type="text"
                  onChange={handleChangeSingleInput}
                  value={values.franchiseEmail}
                  required=""
                  placeholder="Nasi Goreng"
                  valid={isValid.franchiseEmail && isTouched.franchiseEmail}
                  invalid={!isValid.franchiseEmail && isTouched.franchiseEmail} />
                <FormFeedback valid>Email looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.franchiseEmail && isTouched.franchiseEmail).toString()}>Please enter a valid email.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>{PasswordFranchiseText}</Label>
                <InputGroup>
                  <Input
                    name='franchisePassword'
                    className="form-control"
                    type={togglePassword ? 'text' : 'password'}
                    onChange={handleChangeSingleInput}
                    value={values.franchisePassword}
                    required=""
                    placeholder='Minimal 8 karakter'
                    valid={isValid.franchisePassword && isTouched.franchisePassword}
                    invalid={!isValid.franchisePassword && isTouched.franchisePassword}
                  />
                  <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
                  <FormFeedback valid>Password looks good!</FormFeedback>
                  <FormFeedback invalid={(!isValid.franchisePassword && isTouched.franchisePassword).toString()}>Please enter a valid password.</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup >
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{AddressFranchiseText}</Label>
                <Input
                  name='franchiseAddress'
                  className="form-control"
                  type='textarea'
                  rows="3"
                  cols="50"
                  onChange={handleChangeSingleInput}
                  value={values.franchiseAddress}
                  required=""
                  placeholder="Jalan Kalorize"
                  valid={isValid.franchiseAddress && isTouched.franchiseAddress}
                  invalid={!isValid.franchiseAddress && isTouched.franchiseAddress}
                />
                <FormFeedback valid>Address looks good!</FormFeedback>
                <FormFeedback invalid={(!isValid.franchiseAddress && isTouched.franchiseAddress).toString()}>Please enter a valid address.</FormFeedback>
              </FormGroup>
              <div className="text-sm-end">
                <Btn attrBtn={{ color: 'primary', onClick: (e) => createFranchise(e), disabled: loading ? loading : loading, }}>{loading ? 'LOADING...' : CreateGymText}</Btn>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default AddFranchiseWidgets;


// export default GoogleMapComponent;
