import UsersProfile from '../Pages/Users/UsersProfile';
import UsersEdit from '../Pages/Users/UsersEdit';
import Gym from '../Pages/Admin/Gyms';
import Food from '../Pages/Admin/Foods';
import Franchise from '../Pages/Admin/Franchises';
import UserManagement from '../Pages/Admin/UserManagement';

export const routes = [
    { path: `${process.env.PUBLIC_URL}/admin/gyms`, Component: <Gym /> },
    { path: `${process.env.PUBLIC_URL}/admin/foods`, Component: <Food /> },
    { path: `${process.env.PUBLIC_URL}#`, Component: <Franchise /> },
    { path: `${process.env.PUBLIC_URL}/admin/user-management`, Component: <UserManagement /> },

    { path: `${process.env.PUBLIC_URL}/users/userprofile`, Component: <UsersProfile /> },
    { path: `${process.env.PUBLIC_URL}/users/useredit`, Component: <UsersEdit /> },
];