import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Btn, H4 } from "../../../AbstractElements";
import {
  EditProfile,
  UpdateProfile,
  EmailAddress,
  Name,
  Phone,
} from "../../../Constant";
import {
  getLoggedInUserServiceApi,
  logoutServiceApi,
  updateProfileServiceApi,
} from "../../../Services/Api.Service";
import { Bounce, toast } from "react-toastify";

const EditMyProfile = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onEditSubmit = async (data) => {
    setLoading(true);
    await updateProfileServiceApi(data, userId)
      .then((resp) => {
        if (resp.status !== 200) {
          throw resp;
        } else {
          const fullName = resp.data["data"]["fullname"].split(" ");
          localStorage.setItem("lastName", fullName[fullName.length - 1]);

          toast.success(resp.data["message"], {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            onClose: () => {
              if (
                resp.data["data"]["email"] !== localStorage.getItem("email")
              ) {
                toast.info(
                  "You must log in again because your email address has changed.",
                  {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    onClose: () => {
                      logoutServiceApi();
                    },
                  }
                );
              }
            },
          });
        }
      })
      .catch((error) => {
        if (error.status === 422) {
          const errors = error.data.errors;
          errors.forEach((err, _) => {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          });
        } else {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    async function fetchProfile() {
      await getLoggedInUserServiceApi()
        .then((resp) => {
          if (resp.status !== 200) {
            throw Error(resp.data["errors"]);
          } else {
            setUserId(resp.data["data"]["id_user"]);
            setValue("full_name", resp.data["data"]["fullname"]);
            setValue("phone", resp.data["data"]["no_telepon"]);
            setValue("email", resp.data["data"]["email"]);
          }
        })
        .catch((error) => {
          toast.error("Failed to load profile");
        });
    }

    fetchProfile();
  }, [setValue]);

  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: "card-title mb-0" }}>{EditProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="5">
              <FormGroup className="mb-3">
                <Label className="form-label">{Name}</Label>
                <input
                  className="form-control"
                  name="full_name"
                  type="text"
                  placeholder="Your Name"
                  {...register("full_name", { required: true })}
                />
                <span style={{ color: "red" }}>
                  {errors.company && "Name is required"}{" "}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="3">
              <FormGroup>
                <Label className="form-label">{Phone}</Label>
                <input
                  className="form-control"
                  name="phone"
                  type="text"
                  placeholder="+628123456789"
                  {...register("phone", { required: true })}
                />
                <span style={{ color: "red" }}>
                  {errors.phoneNumber && "Phone Number is required"}{" "}
                </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{EmailAddress}</Label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
                <span style={{ color: "red" }}>
                  {errors.EmailAddress && "Email Address is required"}{" "}
                </span>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn
            attrBtn={{
              color: "primary",
              type: "submit",
              disabled: loading ? loading : loading,
            }}
          >
            {loading ? "LOADING..." : UpdateProfile}
          </Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default EditMyProfile;
