import React, { Fragment } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { RememberMe } from '../../../Constant';

const FormPassword = ({ isRememberMe, handleChangeRememberMe }) => {

    return (
        <Fragment>
            <FormGroup className="login-btn">
                <div className="checkbox">
                    <Input 
                        id="checkbox1" 
                        type="checkbox" 
                        checked={isRememberMe}
                        onChange={handleChangeRememberMe}              
                    />
                    <Label for="checkbox1">{RememberMe}</Label>
                </div>
            </FormGroup>
        </Fragment>
    );
};
export default FormPassword;