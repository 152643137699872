import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import FranchiseList from './FranchiseList';
import AddFranchiseWidgets from './AddFranchiseWidgets';

const FranchiseContain = () => {
  return (
    <Fragment>
      <Container fluid={true} className="default-dash">
        <Row>
          <FranchiseList/>
          <AddFranchiseWidgets/>
        </Row>
      </Container>
    </Fragment>
  );
};
export default FranchiseContain;