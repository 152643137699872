import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import {
  CreateGymText,
  GymAddressText,
  GymNameText,
  GymUploadImageText,
} from "../../../Constant";
import { Bounce, toast } from "react-toastify";
import { MapContainer, TileLayer } from "react-leaflet";
import { LocationMarker } from "../../../Layout/LeafletMapLayout";
import { addGymServiceApi } from "../../../Services/Api.Service";

const CreateGymWidgets = ({ getAllGym }) => {
  const [map, setMap] = useState(null);

  const handleAddress = (currentAddress, currentPosition) => {
    setValues({
      ...values,
      gymAddress: currentAddress,
      latitude: currentPosition.lat,
      longitude: currentPosition.lng,
      linkGoogle: `https://www.google.com/maps/search/?api=1&query=${currentPosition.lat}%2C${currentPosition.lng}`,
    });

    setIsValid({
      ...isValid,
      gymAddress: true,
      latitude: true,
      longitude: true,
      linkGoogle: true,
    });

    setIsTouched({
      ...isTouched,
      gymAddress: true,
      latitude: true,
      longitude: true,
      linkGoogle: true,
    });
  };

  const handleMapLoad = (map) => {
    setMap(map);
  };

  const requestLocation = (e) => {
    e.preventDefault();
    if (map) {
      map.locate();
    }
  };

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    gymName: "",
    gymAddress: "",
    latitude: null,
    longitude: null,
    linkGoogle: "",
    imageFile: null,
    imageName: "",
  });

  const [isValid, setIsValid] = useState({
    gymName: false,
    gymAddress: false,
    latitude: false,
    longitude: false,
    linkGoogle: false,
    imageFile: false,
    imageName: false,
  });

  const [isTouched, setIsTouched] = useState({
    gymName: false,
    gymAddress: false,
    latitude: false,
    longitude: false,
    linkGoogle: false,
    imageFile: false,
    imageName: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });

    setIsValid({
      ...isValid,
      [name]: value.length > 0,
    });

    setIsTouched({
      ...isTouched,
      [name]: true,
    });
  };

  const createGym = async (e) => {
    if (Object.values(isValid).every((value) => value)) {
      setLoading(true);
      try {
        await addGymServiceApi(values).then((resp) => {
          switch (resp.status) {
            case 200:
              toast.success(resp.data["message"], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });

              setValues({
                gymName: "",
                gymAddress: "",
                latitude: null,
                longitude: null,
                linkGoogle: "",
                imageFile: null,
                imageName: "",
              });
              setIsValid({
                gymName: false,
                gymAddress: false,
                latitude: false,
                longitude: false,
                linkGoogle: false,
                imageFile: false,
                imageName: false,
              });
              setIsTouched({
                gymName: false,
                gymAddress: false,
                latitude: false,
                longitude: false,
                linkGoogle: false,
                imageFile: false,
                imageName: false,
              });

              getAllGym();
              break;
            default:
              throw new Error(resp);
          }
        });
      } catch (error) {
        if (error.status === 422) {
          const errors = error.data.errors;
          errors.forEach((err, _) => {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          });
        } else {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }
      setLoading(false);
    } else {
      toast.error("Please fill all", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleFileChange = (event) => {
    setValues({
      ...values,
      imageFile: event.target.files[0],
      imageName: event.target.files[0].name,
    });

    setIsTouched({
      ...isTouched,
      imageFile: true,
      imageName: true,
    });

    setIsValid({
      ...isValid,
      imageFile: true,
      imageName: true,
    });
  };

  return (
    <Fragment>
      <Col xl="6" lg="12" className="dash-xl-100 box-col-12">
        <Card>
          <CardHeader>
            <Media>
              <Media body>
                <H5>{CreateGymText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="contact-form">
            <Form>
              <Row className="theme-form e-commerce-form">
                <FormGroup className="mb-lg-3 col-lg-6">
                  <Label for="exampleInputName">{GymNameText}</Label>
                  <Input
                    name="gymName"
                    className="form-control"
                    id="exampleInputName"
                    type="text"
                    onChange={handleChange}
                    value={values.gymName}
                    required=""
                    placeholder="Kalorize"
                    valid={isValid.gymName && isTouched.gymName}
                    invalid={!isValid.gymName && isTouched.gymName}
                  />
                  <FormFeedback valid>Name looks good!</FormFeedback>
                  <FormFeedback
                    invalid={(!isValid.gymName && isTouched.gymName).toString()}
                  >
                    Please enter a valid name.
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-lg-3 col-lg-6">
                  <Label for="exampleInputName">{GymUploadImageText}</Label>
                  <Input
                    name="imageFile"
                    className="form-control"
                    id="exampleInputName"
                    type="file"
                    onChange={handleFileChange}
                    valid={isValid.imageFile && isTouched.imageFile}
                    invalid={!isValid.imageFile && isTouched.imageFile}
                  />
                  <FormFeedback valid>Name looks good!</FormFeedback>
                  <FormFeedback
                    invalid={(
                      !isValid.imageFile && isTouched.imageFile
                    ).toString()}
                  >
                    Please enter a valid name.
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <MapContainer
                    className="jvector-map-height"
                    style={{ height: 389 }}
                    center={[50, 10]}
                    zoom={13}
                    whenCreated={handleMapLoad}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    <LocationMarker handleAddress={handleAddress} />
                  </MapContainer>
                  <Button onClick={requestLocation} className="my-1">
                    My Location
                  </Button>
                  <br />
                  <Label
                    className="col-form-label"
                    htmlFor="exampleInputEmail1"
                  >
                    {GymAddressText}
                  </Label>
                  <Input
                    name="gymAddress"
                    className="form-control"
                    type="textarea"
                    rows="3"
                    cols="50"
                    onChange={handleChange}
                    value={values.gymAddress}
                    required=""
                    placeholder="Jalan Kalorize"
                    valid={isValid.gymAddress && isTouched.gymAddress}
                    invalid={!isValid.gymAddress && isTouched.gymAddress}
                  />
                  <FormFeedback valid>Address looks good!</FormFeedback>
                  <FormFeedback
                    invalid={(
                      !isValid.gymAddress && isTouched.gymAddress
                    ).toString()}
                  >
                    Please enter a valid address.
                  </FormFeedback>
                </FormGroup>
                <div className="text-sm-end">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: (e) => createGym(e),
                      disabled: loading ? loading : loading,
                    }}
                  >
                    {loading ? "LOADING..." : CreateGymText}
                  </Btn>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default CreateGymWidgets;
