const api = `https://api.kalorize.com/api/v1`;
// const api = `http://127.0.0.1:8080/api/v1`;
export const LoginApi = `${api}/admin/login`;
export const LogoutApi = `${api}/user/logout`;
export const LoggedInUserApi = `${api}/admin/user`;
export const RefreshTokenApi = `${api}/admin/refresh`;
export const EditPasswordApi = `${api}/admin/password`;
export const EditProfileApi = `${api}/admin/user/`;

export const CreateGymApi = `${api}/admin/gyms`;
export const GetAllGymApi = `${api}/gyms`;
export const GenerateCodeGymApi = `${api}/admin/generate-gym-token`;
export const CreateFoodApi = `${api}/admin/create-makanan`;
export const GetAllFoodApi = `${api}/makanan`;
export const GetAllUserApi = `${api}/list/user`;
export const DeleteUserApi = `${api}/admin/delete-user`;
export const UpdateUserApi = `${api}/users/`;
export const CreateFranchiseApi = `${api}/admin/create-franchise`;
