import React, { Fragment, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../Layout/Loader';
import LayoutRoutes from './LayoutRoutes';
import Callback from '../Auth/Callback';
import { authRoutes } from './AuthRoutes';
import PrivateRoute from './PrivateRoute';
import Signin from '../Auth/Signin';
const Routers = () => {
  const access_token = localStorage.getItem('accessToken');

  return (
    <Fragment>
      <BrowserRouter basename={'/'}>
        <>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path={'/'} element={<PrivateRoute />}>
                {
                  access_token
                    ? <>
                      <Route exact
                        path={`${process.env.PUBLIC_URL}`}
                        element={<Navigate to={`${process.env.PUBLIC_URL}/admin/gyms`} />}
                      />
                      <Route exact
                        path={`/`}
                        element={<Navigate to={`${process.env.PUBLIC_URL}/admin/gyms`} />}
                      />
                      <Route exact
                        path={`${process.env.PUBLIC_URL}/login`}
                        element={<Navigate to={`${process.env.PUBLIC_URL}/admin/gyms`} />}
                      />
                    </>
                    : <>
                      <Route exact
                        path={`${process.env.PUBLIC_URL}`}
                        element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
                      />
                      <Route exact
                        path={`/`}
                        element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
                      />
                    </>
                }
                <Route path={`/*`} element={<LayoutRoutes />} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
              <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
              {authRoutes.map(({ path, Component }, i) => (
                <Route path={path} element={Component} key={i} />
              ))}
            </Routes>
          </Suspense>
        </>
      </BrowserRouter>
    </Fragment >
  );
};
export default Routers;