import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col } from 'reactstrap';
import { H4, H6, Image } from '../../../AbstractElements';
import Img from '../../../assets/images/logo/logo.png';
import man from '../../../assets/images/dashboard/1.png';

const ProfileHeader = ({profile}) => {

  return (
    <Fragment>
      <Col sm="12">
        <Card className="profile-header bg-image" style={{ backgroundImage: `url(${Img})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
          <div className="profile-img-wrrap">
            <Image style={{ backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }} attrImage={{ className: 'img-fluid bg-img-cover', src: `${require('../../../assets/images/user-profile/bg-profile.jpg')}`, alt: '' }} /></div>
          <div className="userpro-box">
            <div className="img-wrraper">
              <div className="avatar"><Image attrImage={{ className: 'img-fluid', alt: '', src: `${profile.foto ? profile.foto : man}` }} /></div>
              <Link className="icon-wrapper" to={`${process.env.PUBLIC_URL}/users/useredit`}>
                <i className="icofont icofont-pencil-alt-5"></i>
              </Link>
            </div>
            <div className="user-designation">
              <div className="title">
                <a target="_blank" href="#javascript">
                  <H4>{profile.firstName + " " + profile.lastName}</H4>
                  <H6>{profile.role}</H6>
                </a>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProfileHeader;