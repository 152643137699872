import axios from "axios";
import {
  CreateFoodApi,
  CreateGymApi,
  DeleteUserApi,
  EditPasswordApi,
  EditProfileApi,
  GenerateCodeGymApi,
  GetAllFoodApi,
  GetAllGymApi,
  GetAllUserApi,
  LoggedInUserApi,
  LoginApi,
  RefreshTokenApi,
  UpdateUserApi,
} from "../api";
import { authFileHeader, authHeader } from "./Fack.Backend";
import { toast } from "react-toastify";

export const loginServiceApi = async (email, password) => {
  try {
    const response = await axios.post(LoginApi, {
      email: email,
      password: password,
    });
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const logoutServiceApi = async () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("email");
  localStorage.removeItem("lastName");
  window.location.href = `${process.env.PUBLIC_URL}/login`;
};

export const getAllFoodServiceApi = async () => {
  try {
    const response = await axios.get(GetAllFoodApi, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const getAllUserServiceApi = async () => {
  try {
    const response = await axios.get(GetAllUserApi, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const deleteUserServiceApi = async (id) => {
  try {
    const response = await axios.delete(`${DeleteUserApi}/${id}`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const updateUserServiceApi = async (id, data) => {
  try {
    console.log(data);
    console.log(data.name);
    console.log(data.age);
    const formData = new FormData();
    data.imageFile && formData.append("file", data.imageFile);

    const response = await axios.put(
      `${UpdateUserApi}${id}`,
      {
        fullname: data.name,
        email: data.email,
        umur: data.age,
        berat_badan: data.weight,
        tinggi_badan: data.height,
        frekuensi_gym: data.gymFrequency,
        target_kalori: data.calorieTarget,
        no_telepon: data.phoneNumber,
      },
      {
        headers: authHeader(),
      }
    );

    if (data.imageFile) {
      const responseUploadPhoto = await axios.patch(`${UpdateUserApi}${id}/photo`, formData, {
        headers: authFileHeader(),
      });
      response.data["data"]["foto_url"] = responseUploadPhoto.data["data"]["foto"]
    }

    console.log(response);
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const addFoodServiceApi = async (data) => {
  try {
    const response = await axios.post(
      CreateFoodApi,
      {
        namaMakanan: data.foodName,
        kalori: parseFloat(data.calorie),
        protein: parseFloat(data.protein),
        bahan: data.ingredients,
        cookingStep: data.cookingSteps,
        listFranchise: [],
      },
      { headers: authHeader() }
    );
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const addGymServiceApi = async (data) => {
  try {
    console.log(data);
    const formData = new FormData();
    formData.append("gym_name", data.gymName);
    formData.append("gym_address", data.gymAddress);
    formData.append("latitude", data.latitude);
    formData.append("longitude", data.longitude);
    formData.append("link_google", data.linkGoogle);
    formData.append("gym_image", data.imageFile);
    const response = await axios.post(CreateGymApi, formData, {
      headers: authFileHeader(),
    });
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const getAllGymServiceApi = async () => {
  try {
    const response = await axios.get(GetAllGymApi, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const generateCodeGymServiceApi = async (idGym) => {
  console.log(idGym);
  try {
    const response = await axios.post(
      GenerateCodeGymApi,
      {
        uid: idGym,
      },
      {
        headers: authHeader(),
      }
    );
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const getAccessTokenServiceApi = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const response = await axios.post(RefreshTokenApi, {
      refresh_token: refreshToken ? refreshToken : "",
    });

    switch (response.status) {
      case 200:
        localStorage.setItem(
          "accessToken",
          response.data["data"]["accessToken"]
        );
        localStorage.setItem(
          "refreshToken",
          response.data["data"]["refreshToken"]
        );
        window.location.reload();
        break;
      default:
        logoutServiceApi();
    }
  } catch (error) {
    switch (error.response.status) {
      case 401:
        toast.info("Your session has expired, please login again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            logoutServiceApi();
          },
        });
        break;
      default:
        throw error.response;
    }
  }
};

export const getLoggedInUserServiceApi = async () => {
  try {
    const response = await axios.get(LoggedInUserApi, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      await getAccessTokenServiceApi();
    } else {
      throw error.response;
    }
  }
};

export const updateProfileServiceApi = async (newProfile, userId) => {
  try {
    const response = await axios.put(
      EditProfileApi + userId,
      {
        full_name: newProfile.full_name,
        email: newProfile.email,
        phone: newProfile.phone,
      },
      {
        headers: authHeader(),
      }
    );
    return response;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};

export const updatePasswordServiceApi = async (passwordData) => {
  try {
    const response = await axios.patch(
      EditPasswordApi,
      {
        old_password: passwordData.oldPassword,
        new_password: passwordData.newPassword,
        confirm_password: passwordData.retypePassword,
      },
      {
        headers: authHeader(),
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    switch (error.response.status) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
};
