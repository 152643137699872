import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import UserManagementContain from '../../../Component/Admin/UserManagement';

const UserManagement = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Admin" title="User Management" />
      <UserManagementContain />
    </Fragment>
  );
};
export default UserManagement;