import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Media } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { FoodCalorieText, FoodCookingStepsText, FoodDetailText, FoodIngredientsText, FoodNameText, FoodProteinText } from '../../../Constant';
import man from '../../../assets/images/dashboard/1.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const DetailFood = ({ food }) => {
  const [isOpenPhoto, setIsOpenPhoto] = useState(false);

  return (
    <Fragment>
      <Col xl='6' className='xl-50 box-col-6'>
        <Card>
          <CardHeader>
            <Media>
              <Media body>
                <H5>{FoodDetailText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="contact-form">
            <Form className="theme-form">
              <div className="form-icon"><i className="icofont icofont-envelope-open"></i></div>
              <img
                src={food ? food.image ? food.image : man : man}
                alt={food && food.name}
                className='rounded mb-3'
                style={{ height: '150px', cursor: 'pointer' }}
                onClick={() => setIsOpenPhoto(true)}
              />
              <FormGroup>
                <Label for="exampleInputName">{FoodNameText}</Label>
                <Input
                  name='foodName'
                  className="form-control"
                  id="exampleInputName"
                  type="text"
                  value={food ? food.name : ''}
                  required=""
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodCalorieText}</Label>
                <Input
                  name='calorie'
                  className="form-control"
                  type='number'
                  rows="3"
                  cols="50"
                  value={food ? food.calorie : ''}
                  required=""
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodProteinText}</Label>
                <Input
                  name='protein'
                  className="form-control"
                  id="exampleInputEmail1"
                  type="number"
                  value={food ? food.protein : ''}
                  required=""
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodIngredientsText}</Label>
                {food ?
                  <ListGroup>
                    {food.ingredient.map((item, index) => (
                      <ListGroupItem key={index} className="d-flex justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <span>{item}</span>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  : null
                }
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">{FoodCookingStepsText}</Label>
                {food ?
                  <ListGroup>
                    {food.cookingStep.map((item, index) => (
                      <ListGroupItem key={index} className="d-flex justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <span>{item}</span>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  : null
                }
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Col>      
      {isOpenPhoto && (
        <Lightbox
          mainSrc={food ? food.image ? food.image : man : man}
          onCloseRequest={() => setIsOpenPhoto(false)}
        />
      )}
    </Fragment>
  );
};
export default DetailFood;