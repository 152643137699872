import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import { Btn, H5, LI, P, UL } from '../../../AbstractElements';
import { Book, Briefcase, Droplet, Heart, MapPin } from 'react-feather';

const AboutMeSidebar = ({profile}) => {
  const [aboutme, setisAboutme] = useState(true);
  const toggleClick = () => {
    setisAboutme(!aboutme);
  };

  const AboutMeData = [
    {
        id: 1,
        icon: <Briefcase />,
        designation: 'Gym',
        place: profile.Gym
    },
    {
        id: 2,
        icon: <Book />,
        designation: 'Gym Code',
        place: profile.KodeGym
    },
    {
        id: 3,
        icon: <Heart />,
        designation: 'Berat Badan',
        place: profile.beratBadan
    },
    {
        id: 4,
        icon: <MapPin />,
        designation: 'Email',
        place: profile.email
    },
    {
        id: 5,
        icon: <Droplet />,
        designation: 'Frekuensi Gym',
        place: profile.frekuensiGym
    },
    {
        id: 6,
        icon: <Droplet />,
        designation: 'Jenis Kelamin',
        place: profile.jenisKelamin ? 'Laki-Laki' : "Perempuan"
    },
    {
        id: 7,
        icon: <Droplet />,
        designation: 'No Telepon',
        place: profile.noTelepon
    },
    {
        id: 8,
        icon: <Droplet />,
        designation: 'Target Kalori',
        place: profile.targetKalori
    },
    {
        id: 9,
        icon: <Droplet />,
        designation: 'Tinggi Badan',
        place: profile.tinggiBadan
    },
    {
        id: 10,
        icon: <Droplet />,
        designation: 'Umur',
        place: profile.umur + " Tahun"
    },
];

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'p-0' }}>
              <Btn attrBtn={{
                className: 'btn btn-link', databstoggle: 'collapse', databstarget: '#collapseicon4', ariaexpanded: 'true', ariacontrols: 'collapseicon4', color: 'transperant', onClick: toggleClick
              }} >About Me</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={aboutme}>
            <CardBody className="post-about">
              <UL attrUL={{ as: 'ul', className: 'simple-list' }}>
                {AboutMeData.map((item) =>
                  <LI key={item.id}>
                    <div className="icon">{item.icon}</div>
                    <div>
                      <H5>{item.designation}</H5>
                      <P>{item.place}</P>
                    </div>
                  </LI>
                )}
              </UL>
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment >
  );
};
export default AboutMeSidebar;