import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
} from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import {
  GenerateGymCodeText,
  GenerateText,
  GymAddressText,
  GymDetailText,
  GymNameText,
} from "../../../Constant";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import CopyToClipboard from "react-copy-to-clipboard";
import { latLng } from "leaflet";
import { generateCodeGymServiceApi } from "../../../Services/Api.Service";
import { toast } from "react-toastify";
import man from "../../../assets/images/dashboard/1.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const DetailGym = ({ gym }) => {
  const [gymCode, setGymCode] = useState({
    value: "",
    copied: false,
  });
  const [map, setMap] = useState(null);
  const [isOpenPhoto, setIsOpenPhoto] = useState(false);

  const handleMapLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (map && gym)
      map.flyTo(latLng(gym.latitude, gym.longitude), map.getZoom());
  }, [map, gym]);

  const [loading, setLoading] = useState(false);

  const generateCodeGym = async (e) => {
    if (gym) {
      try {
        await generateCodeGymServiceApi(gym.id).then(async (resp) => {
          console.log(resp);
          switch (resp.data["statusCode"]) {
            case 200:
              setGymCode({
                ...gymCode,
                value: resp.data["data"]["kode_gym"],
              });
              break;
            default:
              throw new Error(resp);
          }
        });
      } catch (error) {
        setLoading(false);
        toast.error("Failed to load Gym");
      }
    } else {
      toast.error("Choose Gym first");
    }
  };

  return (
    <Fragment>
      <Col xl="6" className="xl-50 box-col-6">
        <Card>
          <CardHeader>
            <Media>
              <Media body>
                <H5>{GymDetailText}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="contact-form">
            <Form className="theme-form">
              <div className="form-icon">
                <i className="icofont icofont-envelope-open"></i>
              </div>
              <img
                src={gym ? (gym.img ? gym.img : man) : man}
                alt={gym && gym.name}
                className="rounded mb-3"
                style={{ height: "150px", cursor: "pointer" }}
                onClick={() => setIsOpenPhoto(true)}
              />
              <FormGroup>
                <Label for="exampleInputName">{GymNameText}</Label>
                <Input
                  name="gymName"
                  className="form-control"
                  id="exampleInputName"
                  type="text"
                  value={gym ? gym.name : ""}
                  required=""
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleInputName">{GenerateGymCodeText}</Label>
                <Input
                  className="form-control"
                  id="clipboardExample1"
                  type="text"
                  value={gymCode.value}
                  disabled
                />
                <div className="mt-3 d-flex justify-content-between">
                  <Btn
                    attrBtn={{
                      color: "secondary",
                      onClick: (e) => generateCodeGym(e),
                      disabled: loading ? loading : loading,
                    }}
                  >
                    {loading ? "LOADING..." : GenerateText}
                  </Btn>
                  <CopyToClipboard
                    text={gymCode.value}
                    onCopy={() => setGymCode({ copied: true })}
                  >
                    <Button className="btn-clipboard me-2" color="primary">
                      <i className="fa fa-copy"></i> Copy
                    </Button>
                  </CopyToClipboard>
                </div>
              </FormGroup>
              <FormGroup>
                <MapContainer
                  className="jvector-map-height"
                  style={{ height: 389 }}
                  center={[50, 10]}
                  zoom={13}
                  whenCreated={handleMapLoad}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                  {gym ? (
                    <Marker position={latLng(gym.latitude, gym.longitude)}>
                      <Popup>{gym.name}</Popup>
                    </Marker>
                  ) : null}
                </MapContainer>
                <Label className="col-form-label" htmlFor="exampleInputEmail1">
                  {GymAddressText}
                </Label>
                <Input
                  name="gymAddress"
                  className="form-control"
                  type="textarea"
                  rows="3"
                  cols="50"
                  value={gym ? gym.address : ""}
                  disabled
                />
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {isOpenPhoto && (
        <Lightbox
          mainSrc={gym ? (gym.img ? gym.img : man) : man}
          onCloseRequest={() => setIsOpenPhoto(false)}
        />
      )}
    </Fragment>
  );
};
export default DetailGym;
