import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const access_token = localStorage.getItem('accessToken');

    return (
        access_token
            ?
            <Outlet />
            : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
    );
};

export default PrivateRoute;

